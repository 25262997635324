import { Action } from "@ngrx/store";
import { Question } from "../model/quote.interface";

export const INITALISE_QUOTE = '[QUOTE] Initalise';
export const UPDATE_QUESTION = '[QUOTE] Update Question';
export const QUOTE_FAILURE = '[QUOTE] Failure';

export class InitaliseQuote implements Action {
    readonly type = INITALISE_QUOTE;
    constructor() { }
}

export class UpdateQuestion implements Action {
    readonly type = UPDATE_QUESTION;
    constructor(public payload: Question) {}
}

export class QuoteFailure implements Action {
    readonly type = QUOTE_FAILURE;
    constructor(public payload: any) { }
}

export type QuoteActions = InitaliseQuote | UpdateQuestion | QuoteFailure;