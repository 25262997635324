import { Question, Quote } from "../ngrx/model/quote.interface";
import { Steps } from "../enums/question.enum";
import { v4 as uuidv4 } from 'uuid';

export const QUESTIONS: Question[] = [
    {
        question: Steps.Type,
        answers: [],
        hours: 0
    },
    {
        question: Steps.Pages,
        answers: [],
        hours: 0
    },
    {
        question: Steps.Features,
        answers: [],
        hours: 0
    },
    {
        question: Steps.Addons,
        answers: [],
        hours: 0
    }
]

export const DEFAULT_QUOTE: Quote = {
    id: uuidv4(),
    questions: QUESTIONS,
    hours: 0,
    cost: 0
}