import { ActionReducerMap } from "@ngrx/store";
import { Quote } from "./model/quote.interface";
import { quoteReducer } from "./reducer/quote.reducer";

export interface State {
    quote: Quote;
}

export const reducers: ActionReducerMap<State> = {
    quote: quoteReducer,
}