export enum Page {
  Home = 'home',
  Quote = 'quote',
  Error = 'error',
  Client = 'client',
  Cookies = 'cookies',
  Privacy = 'privacy'
}

export enum Quote {
  Intro = 'intro',
  Type = 'type',
  Pages = 'pages',
  Features = 'features',
  Addons = 'addons',
  Price = 'price'
}