import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GaTrigger } from 'src/app/enums/google.enum';
import { State } from 'src/app/ngrx';
import { Store } from '@ngrx/store';
import { InitaliseQuote } from 'src/app/ngrx/actions/quote.action';

declare let gtag: Function;

@Component({
  selector: 'bitbatbow-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  trigger = GaTrigger.Page;

  constructor(
    public store: Store<State>,
    public router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.google, { trigger: event.urlAfterRedirects } );
      }
    });
  }

  ngOnInit() {
    this.store.dispatch(new InitaliseQuote());
  }
}
