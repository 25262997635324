import { DEFAULT_QUOTE } from 'src/app/data/quote.data';
import * as quote from '../actions/quote.action';
import { Question, Quote } from '../model/quote.interface';

export function quoteReducer(state = DEFAULT_QUOTE, action: quote.QuoteActions) {
    switch(action.type) {
        case quote.INITALISE_QUOTE: {
            return state;
        }
        case quote.UPDATE_QUESTION: {
            const index = state.questions.findIndex(question => question.question === action.payload.question);
            const newArray = [...state.questions];
            newArray[index] = action.payload

            let newState: Quote = {
                id: state.id,
                questions: [ ...newArray ],
                hours: getTotalHours([ ...newArray ]),
                cost: getTotalCost([ ...newArray ]) 
            }
            
            return newState;
        }
        default: {
            state
        }
    }
}

function getTotalHours(questions: Question[]): number {
    let total = 0;
    questions.forEach(question => {
        total += question.hours;
    });
    return total;
}

function getTotalCost(questions: Question[]): number {
    let total = 0;
    questions.forEach(question => {
        total += question.hours;
    });
    return total * 25;
}